import _pluralize from "pluralize";
import { escapeRegExp, keys, reduce } from "lodash";
import { join } from "lodash/fp";
import {
  sentenceCase as _sentenceCase,
  capitalCase as _capitalCase,
} from "change-case";
import spaceCase from "to-space-case";
import { titleCase as titleNoSpaceCase } from "title-case";

import { Maybe } from "./maybe";
import { composel } from "./fn";
import { omitEmpty } from "./array";

const STRIP_REGEX = /[^a-zA-Z0-9\(\)\&\"\'\/]/g;
const ACRONYM_REGEX = /\b([A-Z]{2,})\b/g;

export const capitalCase = (text: string) =>
  _capitalCase(text, {
    stripRegexp: STRIP_REGEX,
  });

// Wraps change-case sentenceCase with acronym handling
export function sentenceCase(input: string): string {
  // Find all acronyms
  const acronyms = input.match(ACRONYM_REGEX);

  // Convert the input to sentence case using the sentenceCase function
  const result = _sentenceCase(input, { stripRegexp: STRIP_REGEX });

  if (!acronyms?.length) {
    return result;
  }

  // Restore the acronyms to the result
  return reduce(
    acronyms,
    (res, acronym) => {
      // Use a case-insensitive regex to replace sentence-cased acronym with the original one
      const regex = new RegExp(`\\b${acronym.toLowerCase()}\\b`, "gi");
      return res.replace(regex, acronym);
    },
    result
  );
}

export const lowerSentenceCase = (s?: string) =>
  _sentenceCase(s || "")?.toLowerCase();

export const titleCase = composel(spaceCase, titleNoSpaceCase);

export const limit = (s: string, limit: number) => s.substring(0, limit);
export const clipLimit = (s: string, limit: number) =>
  s.length > limit ? s.substring(0, limit) + "..." : s;

_pluralize.addUncountableRule("content");
_pluralize.addUncountableRule("work");

// Wraps pluralize with optional num argument and acronym handling
export function plural(word: Maybe<string>, num?: Maybe<number>): string;
export function plural(word: Maybe<string>, num: Maybe<number> = 2) {
  if (!word) {
    return word;
  }

  // Check if acronym, and add lowercase s to end regardless
  if (word.match(ACRONYM_REGEX)) {
    return num > 1 ? word + "s" : word;
  }

  return _pluralize(word, num);
}
// Alias
export const pluralize = plural;

export const prefix = (pre: string) => (s: string) => pre + s;
export const suffix = (post: string) => (s: string) => s + post;

export const replaceAll = (str: string, find: string, replace: string) =>
  str.replace(new RegExp(escapeRegExp(find), "g"), replace);

export const replaceAll_ = (find: string, replace: string) => (val: string) =>
  replaceAll(val, find, replace);

// Takes a template string which includes named variables like {this} and an object with the values like {this: "that"}
export const format = (template: string, values: Record<string, string>) =>
  reduce(
    keys(values),
    (acc, key) => replaceAll(acc, `{${key}}`, values[key]),
    template
  );

export const toSentence = composel(omitEmpty, join(" "), sentenceCase);

export const wrap = (pre: string, post: string) => (s: string) =>
  pre + s + post;
