import { useMemo } from "react";

import { Campaign } from "@api";

import { useUpdateEntity } from "@state/generic";

import { when } from "@utils/maybe";
import { asMutation } from "@utils/property-mutations";

import { useCurrentPage } from "@ui/app-page";
import { Centered, Container } from "@ui/container";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Swatch } from "@ui/icon";
import { LabelledPropertyValueList } from "@ui/property-value-tile";
import { Sheet } from "@ui/sheet-layout";
import { WorkflowActions } from "@ui/workflow-action-button";
import { Button } from "@ui/button";
import { CodeLabel } from "@ui/code-label";
import { EditableHeading } from "@ui/editable-heading";
import { MagicEmojiSelect } from "@ui/select/emoji";
import { TemplateBanner } from "@ui/template-banner";
import { SummarySection } from "@ui/summary-section";
import { ColorSelect } from "@ui/select/color";
import { TextHighlight } from "@ui/text-highlight";
import { EntityHeaderBar } from "@ui/entity-header-bar";

import { PaneOpts } from "../types";

import styles from "./styles.module.css";
import { EntityProperties } from "@ui/entity-properties";
import { usePushTo } from "@utils/navigation";
import { SystemPackages, useHasPackages } from "@state/packages";
import { useTeamId } from "@state/teams";
import { GenericPageBody } from "@ui/page-body";
import { ResourcesSection } from "@ui/resources-section";
import { ViewRelationsSection } from "@ui/view-results-section";

export const CampaignPane = ({ id, item }: PaneOpts<Campaign>) => {
  const pushTo = usePushTo();
  const installed = useHasPackages(useTeamId(item) || "", [
    SystemPackages.Outcomes,
    SystemPackages.Content,
    SystemPackages.Tasks,
  ]);
  return (
    <Sheet size="primary">
      {!!item.template && <TemplateBanner />}

      <Header item={item} />

      <Centered stack="vertical" gap={30} padding="horizontal">
        <SummarySection entityId={id} />

        <GenericPageBody
          label="Campaign Brief"
          placeholder="What is this campaign all about..."
          entity={{ id: item.id, entity: "campaign" }}
          body={item.body}
          showAll={false}
        />

        <ResourcesSection entity={item} refs={item.refs?.resources} />

        {installed[SystemPackages.Outcomes] && (
          <ViewRelationsSection
            parentId={item.id}
            childType="outcome"
            onOpen={pushTo}
          />
        )}

        {installed[SystemPackages.Content] && (
          <ViewRelationsSection
            parentId={item.id}
            childType="content"
            onOpen={pushTo}
          />
        )}

        {installed[SystemPackages.Tasks] && (
          <ViewRelationsSection
            parentId={item.id}
            childType="task"
            onOpen={pushTo}
          />
        )}
      </Centered>
    </Sheet>
  );
};

interface HeaderProps {
  item: Campaign;
}

const Header = ({ item }: HeaderProps) => {
  const pageId = useCurrentPage();
  const mutate = useUpdateEntity(item.id, pageId);
  const color = useMemo(() => item.color, [item.color]);

  if (!item) {
    return <h1>Not found.</h1>;
  }

  return (
    <>
      <Container>
        <EntityHeaderBar entity={item} />
      </Container>

      <Centered className={styles.container}>
        <VStack gap={20}>
          <SpaceBetween align="flex-end">
            <VStack gap={20}>
              <Container gap={6} padding="none" inset="bottom" stack="vertical">
                <HStack>
                  <TextHighlight color={item.color}>
                    <MagicEmojiSelect
                      key={`${item.id}-emoji`}
                      entity={item}
                      size="large"
                      emoji={item.icon || "🎨"}
                      onChange={(emoji) =>
                        mutate(
                          asMutation({ field: "icon", type: "text" }, emoji)
                        )
                      }
                    />

                    <EditableHeading
                      key={`${item.id}-heading`}
                      text={item.name || ""}
                      onChange={(text) => {
                        when(text, (i) => {
                          mutate(
                            asMutation({ field: "name", type: "text" }, i)
                          );
                        });
                      }}
                    />
                  </TextHighlight>
                  <CodeLabel code={item?.code} />
                </HStack>
              </Container>
            </VStack>
            <HStack gap={2}>
              <ColorSelect
                className={{ dropdown: styles.dropdown }}
                color={color}
                onChange={(c) =>
                  mutate(asMutation({ field: "color", type: "text" }, c))
                }
              >
                <Button
                  size="small"
                  subtle
                  icon={<Swatch color={color || "gray_5"} />}
                />
              </ColorSelect>
            </HStack>
          </SpaceBetween>

          <LabelledPropertyValueList entity={item} onChange={mutate} />

          {!item.template && (
            <HStack gap={4}>
              <WorkflowActions entity={item} />{" "}
            </HStack>
          )}
        </VStack>
      </Centered>
    </>
  );
};
