import { filter, first } from "lodash";
import { useRecoilValue } from "recoil";
import { useCallback, useMemo, useState } from "react";

import { createPage, Entity, ID, Integration, Ref, Team } from "@api";
import { useQueueUpdateEffect } from "@state/store";
import { ActiveWorkspaceId, useCurrentUser } from "@state/workspace";
import { useEntitySettings } from "@state/settings";
import {
  useCreateFromObject,
  useLazyEntities,
  useLazyEntity,
} from "@state/generic";
import { useLazyFetchResults } from "@state/fetch-results";

import { Maybe } from "@utils/maybe";
import { openInNewTab } from "@utils/url";
import { toNotionUrl } from "@utils/notion";
import { toUpdate } from "@utils/property-mutations";
import { extractTeam, toScope } from "@utils/scope";
import { useGoTo } from "@utils/navigation";
import { ensureMany, findPreferential, OneOrMany } from "@utils/array";
import { containsRef } from "@utils/relation-ref";

import { TeamStoreAtom } from "./atoms";
import { newTeam } from "./utils";

export function useTeamId(entity: Maybe<Entity>) {
  return useMemo(
    () => extractTeam(entity?.source?.scope),
    [entity?.source.scope]
  );
}

export function useLazyGetTeam(teamId: ID) {
  return useLazyEntity<"team">(teamId);
}

export function useLazyAllTeams() {
  const teams = useLazyFetchResults(
    "all-teams",
    "team",
    useMemo(() => ({ and: [] }), []),
    { archived: false, templates: false }
  );

  return teams;
}

export function useLazyTeamOrDefault(teamId?: ID) {
  return useLazyEntity<"team">(teamId);
}

export function useLazyTeam(teamId: ID) {
  return useLazyEntity<"team">(teamId);
}

export function useCreateTeam(pageId?: ID, defaults?: Partial<Team>) {
  const me = useCurrentUser();
  const createInStore = useCreateFromObject("team", defaults?.source?.scope);
  const workspaceID = useRecoilValue(ActiveWorkspaceId);
  const [team, setTeam] = useState(
    newTeam({
      owner: me,
      people: me ? [{ id: me.id }] : [],
      ...defaults,
      source: {
        source: Integration.Traction,
        type: "team",
        scope: toScope(workspaceID),
      },
    })
  );

  const create = useCallback(() => {
    const [saved] = createInStore?.([team]) || [];
    return saved;
  }, [team, createInStore]);

  return useMemo(
    () => ({
      team,
      setTeam,
      create,
    }),
    [team, create]
  );
}

export function useLinkToNotion(teamId: ID) {
  const team = useLazyGetTeam(teamId);
  const update = useQueueUpdateEffect(TeamStoreAtom);

  return useCallback(
    async (id: string) => {
      if (team && !team.notionId) {
        const notionUrl = toNotionUrl(id);

        // Save notionId to DB
        update([
          toUpdate(team, { field: "notionId", type: "text" }, id),
          // toUpdate(team, { field: "links", type: "links" }, [
          //   ...(team.links || []),
          //   { text: "Team Docs", url: notionUrl },
          // ]),
        ]);
      }
    },
    [team?.notionId]
  );
}

// TODO: Make generic so that can be used for projects, teams, etc
// Also need to figure out how to find where to create in notion...
export function useConvertToNotion(teamId: ID) {
  const [converting, setConverting] = useState(false);
  const team = useLazyGetTeam(teamId);
  const link = useLinkToNotion(teamId);

  const convert = useCallback(async () => {
    if (team && !team.notionId) {
      setConverting(true);
      const { id } = await createPage(undefined, team.name || "New Team");

      // Link to team
      link(id);

      // Open automatically (can add to setttings?)
      openInNewTab(toNotionUrl(id));

      setConverting(false);
    }
  }, [team?.name, team?.notionId]);

  return { convert, revert: undefined, converting };
}

export const useLazySettings = (id: ID) => {
  useLazyTeam(id);
  return useEntitySettings(id);
};

export const useLazyTeamsFor = (personId: string) => {
  const teams = useLazyAllTeams();
  return filter(teams, (t) => containsRef(t.people, { id: personId }));
};

export const useLazyMyTeams = () => {
  const me = useCurrentUser();
  return useLazyTeamsFor(me?.id || "");
};

// TODO: Add default team settings to
export const useDefaultTeam = () => {
  const me = useCurrentUser();
  const teams = useLazyAllTeams();

  return findPreferential(
    teams,
    (t) => containsRef(t.people, { id: me.id }),
    () => true
  );
};

export const useLazyPeopleForTeam = (teamId: ID) => {
  const team = useLazyGetTeam(teamId);
  return useLazyEntities<"person">(team?.people || []);
};

export const useTeamPushTo = (teamId: string) => {
  const goTo = useGoTo();
  return useCallback(
    (r: OneOrMany<string | Ref>, params?: Record<string, string>) =>
      goTo([{ id: teamId }, ...ensureMany(r)], params),
    [teamId]
  );
};
