import { map } from "lodash";

import { ID, HasRefs } from "@api";

import {
  useLazyEntities,
  useLazyEntity,
  useQueueUpdates,
} from "@state/generic";
import { useEntityLabels } from "@state/settings";
import { useSuggestedProcesses } from "@state/process";

import { maybeMap, safeAs } from "@utils/maybe";
import { asAppendMutation, asUpdate } from "@utils/property-mutations";
import { usePushTo } from "@utils/navigation";

import { useCurrentPage } from "@ui/app-page";
import { ArrowUpRight, LinkAdd } from "@ui/icon";
import { Sheet } from "@ui/sheet-layout";
import { GlobalEntitySelect } from "@ui/select";
import { PaneContainer, PaneHeader } from "@ui/pane-header";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { HStack } from "@ui/flex";
import { Button } from "@ui/button";

import { render, toEngine } from "..";

export const RelatedProcessesPane = ({ entityId }: { entityId: ID }) => {
  const pageId = useCurrentPage();
  const pushTo = usePushTo();
  const entity = useLazyEntity(entityId);
  const mutate = useQueueUpdates(pageId);
  const toEntityLabel = useEntityLabels(entity?.source.scope, { plural: true });
  const linked = useLazyEntities(
    safeAs<HasRefs>(entity)?.refs?.processes || []
  );
  const { options: suggested, loading } = useSuggestedProcesses(entity);

  return (
    <Sheet size="secondary">
      <PaneHeader title={`Related ${toEntityLabel("process")}`} />

      <PaneContainer>
        <Menu>
          {!!linked?.length && (
            <MenuGroup>
              {map(linked, (item) =>
                render(toEngine(item)?.asMenuItem, {
                  key: item.id,
                  item: item,
                  onChange: (cs) => mutate(asUpdate(item, cs)),
                  onOpen: pushTo,
                  iconRight: ArrowUpRight,
                })
              )}
            </MenuGroup>
          )}
          <MenuGroup label={linked?.length && "Suggested"}>
            {maybeMap(suggested, (item) =>
              item.id === entity?.id
                ? undefined
                : render(toEngine(item)?.asMenuItem, {
                    key: item.id,
                    item: item,
                    onChange: (cs) => mutate(asUpdate(item, cs)),
                    onOpen: pushTo,
                    iconRight: ArrowUpRight,
                  })
            )}
          </MenuGroup>
        </Menu>

        <HStack>
          <GlobalEntitySelect
            value={undefined}
            scope={entity?.source.scope}
            allowed={["process"]}
            onChange={(r) =>
              entity &&
              !!r &&
              mutate(
                asUpdate(
                  entity,
                  asAppendMutation(
                    { field: "refs.processes", type: "relations" },
                    [r]
                  )
                )
              )
            }
            closeOnSelect={true}
            placeholder="Link process"
          >
            <Button inset={true} icon={LinkAdd} subtle size="small">
              Link a{" "}
              {toEntityLabel("process", { case: "lower", plural: false })}
            </Button>
          </GlobalEntitySelect>
        </HStack>
      </PaneContainer>
    </Sheet>
  );
};
