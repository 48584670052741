import { useCallback, useEffect, useMemo, useState } from "react";
import { find, intersection, isString, last, map, sortBy } from "lodash";

import {
  DatabaseID,
  Entity,
  EntityType,
  FetchOptions,
  RelationRef,
  toSearchable,
} from "@api";

import { useSmartSearch } from "@state/generic";
import { useActiveWorkspaceId, useCurrentUser } from "@state/workspace";
import { useEntityLabels } from "@state/settings";
import { useInstalledEntities } from "@state/packages";
import { useLazyAllTeams } from "@state/teams";

import { omitEmpty } from "@utils/object";
import { withHardHandle } from "@utils/event";
import { extractTeam, fromScope, toBaseScope } from "@utils/scope";
import { composel, Fn } from "@utils/fn";
import { dependencySort, reverse } from "@utils/array";
import { isWorkspaceId } from "@utils/id";
import { when } from "@utils/maybe";

import {
  MultiRelationSelect,
  MultiRelationSelectProps,
  RelationSelect,
  RelationSelectProps,
} from "./relation";
import { HStack } from "@ui/flex";
import { MenuItem } from "@ui/menu-item";
import { MenuGroup } from "@ui/menu-group";
import { Button } from "@ui/button";
import { AngleDownIcon, Icon, TeamIcon } from "@ui/icon";
import { useOpenState } from "@ui/dropdown";

import styles from "./select.module.css";
import { SplitControl, SplitMenu } from "./comps";

export type EntitySelectProps = Omit<RelationSelectProps, "options"> & {
  type: EntityType;
  options?: FetchOptions;
};

export type GlobalEntitySelectProps = Omit<EntitySelectProps, "type"> & {
  type?: EntityType;
  scope?: string;
  allowed?: EntityType[] | "*";
  templates?: boolean;
  showTeam?: boolean;
  closeOnTeam?: boolean;
  showOtherTeams?: boolean;
};

export type MultiEntitySelectProps = Omit<
  MultiRelationSelectProps,
  "options"
> & {
  type: EntityType;
};

export type GlobalMultiEntitySelectProps = Omit<
  MultiEntitySelectProps,
  "type"
> & {
  type?: EntityType;
  scope?: string;
  allowed?: EntityType[] | "*";
  templates?: boolean;
  showTeam?: boolean;
  closeOnTeam?: boolean;
  showOtherTeams?: boolean;
};

const toOption = (t: Entity) => ({
  id: t.id,
  name: toSearchable(t),
});

export function EntitySelect({
  type,
  className,
  options: opts,
  open: _open,
  setOpen: _setOpen,
  ...props
}: EntitySelectProps) {
  const [open, setOpen] = useOpenState(_open, _setOpen);
  const { onSearch, options, loading } = useSmartSearch(type, undefined, {
    ...opts,
    toOption,
    empty: open, // Don't search until open
  });

  return (
    <RelationSelect
      {...props}
      className={{ popover: styles.entityPopover }}
      open={open}
      setOpen={setOpen}
      loading={loading}
      options={options}
      onSearch={onSearch}
    />
  );
}

export function EntityMultiSelect({ type, ...props }: MultiEntitySelectProps) {
  const { onSearch, options, loading } = useSmartSearch(type, undefined, {
    toOption,
    archived: false,
    empty: props.open,
  });

  return (
    <MultiRelationSelect
      {...props}
      loading={loading}
      options={options}
      onSearch={onSearch}
    />
  );
}

export function GlobalEntitySelect({
  open: _open,
  setOpen: _setOpen,
  templates,
  showOtherTeams = true,
  className,
  ...props
}: GlobalEntitySelectProps) {
  const workspaceId = useActiveWorkspaceId();
  const [open, setOpen] = useOpenState(_open, _setOpen);

  const [filter, setFilter] = useState<DatabaseID>(() => ({
    type: props.type || "task",
    scope: props.scope || "",
  }));

  const { onSearch, query, options, loading } = useSmartSearch(
    filter?.type,
    filter.type === "team" ? workspaceId : filter?.scope,
    { templates, empty: open, archived: false, toOption }
  );

  const handleScopeChanged = useCallback(
    (changes: Partial<DatabaseID>) =>
      setFilter((e) => ({ ...e, ...omitEmpty(changes) })),
    [setFilter]
  );

  const Menu = useMemo(
    () =>
      open &&
      SplitMenu<RelationRef, false>(
        undefined,
        <SideMenu
          {...props}
          showTeam={props.showTeam ?? true}
          open={open}
          setOpen={setOpen}
          filter={filter}
          onFilterChanged={handleScopeChanged}
        />
      ),
    [open, filter]
  );

  const Control = useMemo(
    () =>
      open &&
      showOtherTeams &&
      SplitControl<RelationRef, false>(
        <ScopeMenu filter={filter} onFilterChanged={handleScopeChanged} />
      ),
    [open, filter]
  );

  // When props input changes
  useEffect(() => {
    handleScopeChanged({
      type: props.type,
      scope: props.scope || "",
    });
  }, [props.scope, props.type]);

  return (
    <RelationSelect
      {...props}
      open={open}
      setOpen={setOpen}
      loading={loading}
      options={options}
      closeOnBlur={false}
      onSearch={onSearch}
      className={{
        ...(!isString(className) ? className : { dropdown: className }),
        popover: styles.splitPopover,
      }}
      overrides={{
        ...props.overrides,
        ...(Menu ? { Menu } : {}),
        ...(Control ? { Control } : {}),
      }}
    />
  );
}

export function GlobalMultiEntitySelect({
  open: _open,
  setOpen: _setOpen,
  templates,
  closeOnTeam,
  showOtherTeams = true,
  ...props
}: GlobalMultiEntitySelectProps) {
  const workspaceId = useActiveWorkspaceId();
  const [open, setOpen] = useOpenState(_open, _setOpen);

  const [filter, setFilter] = useState<DatabaseID>(() => ({
    type: props.type || "task",
    scope: props.scope || "",
  }));

  const { onSearch, options, loading } = useSmartSearch(
    filter?.type,
    filter.type === "team" ? workspaceId : filter?.scope,
    { templates, empty: open, archived: false, toOption }
  );

  const handleScopeChanged = useCallback(
    (changes: Partial<DatabaseID>) =>
      setFilter((e) => ({ ...e, ...omitEmpty(changes) })),
    [setFilter]
  );

  const Menu = useMemo(
    () =>
      open &&
      SplitMenu<RelationRef, true>(
        undefined,
        <SideMenu
          allowed={props.allowed}
          showTeam={props.showTeam ?? true}
          open={open}
          setOpen={setOpen}
          closeOnTeam={closeOnTeam}
          filter={filter}
          onFilterChanged={handleScopeChanged}
        />
      ),
    [filter, open, props.showTeam]
  );

  const Control = useMemo(
    () =>
      open &&
      showOtherTeams &&
      SplitControl<RelationRef, true>(
        <ScopeMenu filter={filter} onFilterChanged={handleScopeChanged} />
      ),
    [open, filter]
  );

  // When props input changes
  useEffect(() => {
    handleScopeChanged({
      type: props.type,
      scope: props.scope || "",
    });
  }, [props.scope, props.type]);

  return (
    <MultiRelationSelect
      {...props}
      open={open}
      setOpen={setOpen}
      loading={loading}
      options={options}
      onSearch={onSearch}
      className={{ popover: styles.splitPopover }}
      overrides={{
        ...props.overrides,
        ...(Menu ? { Menu } : {}),
        ...(Control ? { Control } : {}),
      }}
    />
  );
}

type SplitMenuProps = Pick<
  GlobalEntitySelectProps,
  "allowed" | "open" | "setOpen" | "onChange" | "scope" | "closeOnTeam"
> & {
  showTeam?: boolean;
  filter: DatabaseID;
  onFilterChanged: Fn<Partial<DatabaseID>, void>;
};

function ScopeMenu({ filter, onFilterChanged }: SplitMenuProps) {
  const allTeams = useLazyAllTeams();
  const [selecting, setSelecting] = useState(false);
  const selected = useMemo(
    () => find(allTeams, (t) => filter.scope?.includes(t.id)),
    [allTeams]
  );
  const teams = useMemo(
    () => dependencySort(allTeams, (t) => t.parent?.id),
    [allTeams]
  );

  return (
    <>
      <Button
        size="small"
        iconRight={AngleDownIcon}
        onClick={withHardHandle(() => setSelecting(!selecting))}
      >
        {!!selected && (
          <HStack gap={4}>
            <Icon size="small" icon={<TeamIcon team={selected} />} />
            {selected.name}
          </HStack>
        )}

        {!selected && "Workspace"}
      </Button>

      {selecting && (
        <div className={styles.takeover} onClick={withHardHandle(() => {})}>
          {map(teams, (team) => (
            <MenuItem
              key={team.id}
              icon={<TeamIcon team={team} />}
              onClick={withHardHandle(() => {
                onFilterChanged?.({ scope: team.id });
                setSelecting(false);
              })}
            >
              {team.name}
            </MenuItem>
          ))}
        </div>
      )}
    </>
  );
}

export function SideMenu({
  showTeam,
  allowed,
  scope,
  filter,
  onChange,
  closeOnTeam,
  onFilterChanged,
  setOpen,
}: SplitMenuProps) {
  const me = useCurrentUser();
  const allEntities = useInstalledEntities(
    when(filter.scope, composel(toBaseScope, fromScope, last)) || me.id
  );
  const scopeAllowed = useMemo(() => {
    const all = [...reverse(allEntities), "person"];
    return sortBy(
      (allowed?.[0] === "*" || !allowed?.length
        ? all
        : intersection(all, allowed)) as EntityType[],
      (t) => (["task", "content", "outcome"]?.includes(t) ? `1${t}` : `0${t}`)
    );
  }, [allEntities, allowed]);
  const toLabel = useEntityLabels(scope);

  const handleSelected = useCallback(
    (type: EntityType) => onFilterChanged({ type }),
    [onFilterChanged, filter]
  );

  useEffect(() => {
    if (
      filter.type &&
      when(filter.scope, isWorkspaceId) !== true &&
      !scopeAllowed?.includes(filter.type)
    ) {
      onFilterChanged({
        type:
          find(scopeAllowed, (t) => t !== "team") ||
          scopeAllowed[0] ||
          filter.type,
      });
    }
  }, [filter.type, scopeAllowed]);

  const scopeTeam = useMemo(() => extractTeam(filter.scope), [filter.scope]);

  if (!scopeAllowed.length || scopeAllowed.length < 2) {
    return <></>;
  }

  return (
    <MenuGroup inset={false}>
      {showTeam && !!scopeTeam && onChange && (
        <MenuItem
          onClick={withHardHandle(() => {
            onChange?.({ id: scopeTeam });
            closeOnTeam !== false && setOpen?.(false);
          })}
        >
          Team
        </MenuItem>
      )}

      {map(scopeAllowed, (t: EntityType) => (
        <MenuItem
          key={t}
          selected={t === filter.type}
          onClick={withHardHandle(() => handleSelected(t))}
        >
          {toLabel(t, { plural: true, case: "title" })}
        </MenuItem>
      ))}
    </MenuGroup>
  );
}
