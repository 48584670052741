import { filter, find, isFunction, keys, map, reduce, values } from "lodash";
import { useDebouncedCallback } from "use-debounce";
import { useCallback, useMemo, useRef, useState } from "react";

import {
  AiAssist,
  Color,
  DatabaseID,
  Entity,
  PropertyDef,
  PropertyType,
  PropertyValueRef,
  PropertyVisibility,
  SelectOption,
  PropertyFormat,
  Status,
  StatusGroup,
  PropertyRef,
} from "@api";
import {
  newPropertyDef,
  useCreatePropertyDef,
  useDeletePropertyDef,
  useLazyProperties,
  useLazyPropertyDef,
  useUpdatePropertyDef,
} from "@state/databases";
import { usePropertyTemplates } from "@state/templates";

import { ensureMany, justOne, move, pushDirty, whenEmpty } from "@utils/array";
import { Fn } from "@utils/fn";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { toLabel as toStatusLabel } from "@utils/status";
import { fuzzyMatch } from "@utils/search";
import { ColorSelect, randomColor } from "@ui/select/color";
import { sansDefault } from "@utils/color";
import {
  CUSTOM_PROPERTY_TYPES,
  toKey,
  toLabel,
  toPropertyTypeLabel,
  asPropertyValueRef,
  toCustomField,
  formatsForType,
  toPropertyFormatLabel,
  isAnyRelation,
  isAnySelect,
} from "@utils/property-refs";
import { toBaseScope } from "@utils/scope";
import { cid } from "@utils/id";
import { cx } from "@utils/class-names";

import { CollapsibleSection } from "@ui/collapsible-section";
import { Container } from "@ui/container";
import { EditableText } from "@ui/editable-text";
import {
  DragHandle,
  EditAlt,
  EmojiIcon,
  Icon,
  Swatch,
  TrashAlt,
} from "@ui/icon";
import { Menu } from "@ui/menu";
import {
  AddInlineMenuItem,
  CheckMenuItem,
  EmptyMenuItem,
  MenuItem,
} from "@ui/menu-item";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { StatusIcon } from "@ui/status-button";
import {
  EntityTypeMultiSelect,
  EntityTypeSelect,
} from "@ui/select/entity-type";
import { Tag } from "@ui/tag";
import { Text, TextXLarge } from "@ui/text";
import { Button } from "@ui/button";
import { DeleteButton } from "@ui/confirmation-button";
import { DialogSplit } from "@ui/dialog-split";
import { DropHighlight } from "@ui/drop-highlight";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Field, TextInput } from "@ui/input";
import { MenuGroup } from "@ui/menu-group";
import {
  DragToRef,
  DropTarget,
  usePropertyValueDragDrop,
} from "@ui/property-value-drag-drop";
import { Select } from "@ui/select";
import { EducationTip } from "@ui/education-tip";
import { showError } from "@ui/notifications";
import { LocationLabel } from "@ui/location-button";
import { Divider } from "@ui/divider";

import styles from "./property-edit-dialog.module.css";

interface EditPropertyProps {
  db: DatabaseID;
  prop: PropertyDef<Entity>;
  onChange: Fn<
    | Partial<PropertyDef<Entity>>
    | Fn<PropertyDef<Entity>, Partial<PropertyDef<Entity>>>,
    void
  >;
  onDelete: Fn<PropertyDef<Entity>, void>;
}

interface EditDialogProps {
  prop: PropertyDef<Entity>;
  source: DatabaseID;
  onSaved?: (e: PropertyDef<Entity>) => void;
  onClose?: () => void;
}

const canAiAssist = (def: PropertyDef<Entity>) =>
  (!!def?.type && ["select", "multi_select"]?.includes(def?.type)) ||
  (["relation", "relations"]?.includes(def?.type) &&
    !["task", "outcome", "content"]?.includes(
      justOne(def.options?.references) || ""
    ));

export const PropertyEditDialog = ({
  prop: _prop,
  source: _source,
  onSaved,
  onClose,
}: EditDialogProps) => {
  const isCreating = useMemo(() => !_prop?.field, [_prop]);
  const source = useMemo(() => ({ ..._source, scope: _prop.scope }), [_source]);
  const [prop, setProp] = useState<PropertyDef<Entity>>(_prop);
  const creating = useMemo(() => !_prop?.field, []);
  const [saving, setSaving] = useState(false);
  const [dirty, setDirty] = useState(creating || false);
  const templates = usePropertyTemplates();
  const update = useUpdatePropertyDef(source);
  const create = useCreatePropertyDef(source);
  const deletee = useDeletePropertyDef(source);

  const onDelete = useCallback(
    async (def: PropertyDef<Entity, PropertyType>) => {
      await deletee(def);
      onClose?.();
    },
    [deletee, onClose]
  );

  const onChange = useCallback(
    (changes: Partial<PropertyDef<Entity>> | Fn<PropertyDef<Entity>, void>) => {
      setDirty(true);
      return setProp((p) => {
        const latest = isFunction(changes) ? changes(p) : changes;
        return { ...p, ...latest };
      });
    },
    [prop, setProp]
  );

  const onSave = useCallback(async () => {
    if (!prop.label?.trim()) {
      showError("Field name cannot be empty.");
      return;
    }

    let saved: Maybe<PropertyDef<Entity>> = undefined;
    setSaving(true);
    if (creating) {
      saved = await create(
        { type: prop.type, field: toCustomField(prop.label) },
        prop
      );
    } else {
      saved = await update(_prop, prop);
    }

    setSaving(false);

    onSaved && saved ? onSaved?.(saved) : onClose?.();
  }, [prop, dirty]);

  const recommended = useMemo(
    () =>
      whenEmpty(
        filter(templates, (t) =>
          fuzzyMatch(prop.label || "never", t.search || t.name)
        ),
        []
      ),
    [templates, prop.label]
  );

  const templateSelector = (
    <FillSpace fit="container">
      <Menu>
        <MenuGroup>
          <MenuItem
            icon={EditAlt}
            text="Reset"
            onClick={() => {
              onChange({
                locked: false,
                values: {},
                options: {},
                label: prop.label,
              });
            }}
          />
        </MenuGroup>
        {!!recommended?.length && (
          <MenuGroup label="Templates">
            {map(recommended, (t) => (
              <MenuItem
                key={t.id}
                onClick={() => {
                  onChange({
                    ...t.template,
                    label: prop.label,
                    field: toCustomField(
                      t.template?.field || t.template.label || t.id
                    ),
                  });
                }}
                icon={
                  when(t.icon, (icon) => <EmojiIcon emoji={icon} />) || (
                    <PropertyTypeIcon type={t.template.type as PropertyType} />
                  )
                }
              >
                <HStack>{t.name}</HStack>
              </MenuItem>
            ))}
          </MenuGroup>
        )}
      </Menu>
    </FillSpace>
  );

  return (
    <DialogSplit
      title={
        <SpaceBetween>
          <TextXLarge bold={true}>
            {!creating ? "Edit Field" : "New Field"}
          </TextXLarge>
          <LocationLabel location={source.scope} variant="compact" />
        </SpaceBetween>
      }
      onDismiss={() => !dirty && onClose?.()}
      side={
        <SpaceBetween direction="vertical">
          {isCreating && templateSelector}
          <EducationTip relevantTo={["properties"]} />
        </SpaceBetween>
      }
      actions={
        dirty ? (
          <SpaceBetween>
            <Button onClick={() => onClose?.()}>Discard changes</Button>
            <Button
              variant="primary"
              onClick={() => onSave?.()}
              loading={saving}
            >
              {creating ? "Save field" : "Save changes"}
            </Button>
          </SpaceBetween>
        ) : (
          <Button variant="secondary" onClick={() => onClose?.()}>
            Close
          </Button>
        )
      }
    >
      <EditPropertyDefinition
        db={source}
        prop={prop}
        onChange={onChange}
        onDelete={onDelete}
      />
    </DialogSplit>
  );
};

const EditPropertyDefinition = ({
  db,
  prop: def,
  onChange,
  onDelete,
}: EditPropertyProps) => {
  const available = useMemo(
    () =>
      map(values(CUSTOM_PROPERTY_TYPES), (t) => ({
        id: t,
        name: toPropertyTypeLabel(t),
      })),
    []
  );

  const formatOptions = useMemo(() => {
    const available = formatsForType(def);
    return !!available?.length
      ? map([undefined, ...available], (t) => ({
          id: t,
          name: toPropertyFormatLabel(t),
        }))
      : [];
  }, [def.type]);

  const type = useMemo(
    () => find(available, (t) => t.id === def?.type),
    [available, def?.type]
  );

  const propValues = useMemo(
    () =>
      when(def, (d) =>
        map(d.values[d.type], (v) => asPropertyValueRef(d, { [d.type]: v }))
      ) || [],
    [def?.values]
  );
  const groupedValues = useMemo(() => {
    if (def.type !== "status") {
      return undefined;
    }

    return reduce(
      propValues,
      (acc, ref) => {
        const status = ref.value.status;
        const group = status?.group;

        if (!status || !group) {
          return acc;
        }

        pushDirty(acc[group], ref);

        return acc;
      },
      {
        planning: [],
        "not-started": [],
        "in-progress": [],
        done: [],
      } as Record<StatusGroup, PropertyValueRef<Entity>[]>
    );
  }, [propValues]);

  const onReorder = useCallback(
    (
      ref: PropertyValueRef<Entity, PropertyType>,
      from: number,
      to: DragToRef
    ) =>
      when(ref.value[ref.type], (v) =>
        onChange({
          values: {
            [def.type]: map(
              move(def.values[def.type] as SelectOption[], from, to.order ?? 0),
              (v) => {
                if (
                  ref.type === "status" &&
                  v.id === (ref.value[ref.type] as Maybe<SelectOption>)?.id
                ) {
                  return {
                    ...v,
                    group: to.group || (v as Status).group,
                  };
                }

                return v;
              }
            ),
          },
        })
      ),
    [def]
  );

  const updateValue = useCallback(
    (
      ref: PropertyValueRef<Entity, PropertyType>,
      changes: Maybe<Partial<SelectOption>>
    ) => {
      onChange((def) => ({
        values: {
          [def.type]: maybeMap(def.values[def.type] as SelectOption[], (v) =>
            v.id ===
            ensureMany(ref.value[ref.type] as Maybe<SelectOption | Status>)[0]
              ?.id
              ? changes
                ? { ...v, ...changes }
                : // Filter out when deleting values using undefined
                  undefined
              : v
          ),
        },
      }));
    },
    [onChange]
  );

  const slowSetLabel = useDebouncedCallback(
    (t: string) => t !== def.label && onChange({ label: t }),
    500
  );

  const handleAddValue = (name: string) =>
    onChange({
      values: {
        [def.type]: [
          ...(def.values[def.type] || []),
          {
            id: cid(4),
            name: name,
            color: randomColor(),
            group: def.type === "status" ? "not-started" : "default",
          },
        ],
      },
    });

  if (!def) {
    return <></>;
  }

  return (
    <VStack className={styles.menu} gap={20}>
      <SpaceBetween gap={6}>
        <Field label="Name">
          <TextInput
            autoFocus={false}
            value={def.label || ""}
            onChange={slowSetLabel}
            placeholder="Name this field.."
            updateOn="change"
          />
        </Field>
      </SpaceBetween>

      {def.label && (
        <SpaceBetween gap={6}>
          <Field label="Data Type">
            <Select
              searchable={false}
              options={available}
              value={undefined}
              portal={true}
              toIcon={(v) => <PropertyTypeIcon type={v.id as PropertyType} />}
              onChange={(v) =>
                v &&
                onChange({
                  type: v.id,
                  format: undefined,
                  options: {},
                })
              }
            >
              {type ? (
                <MenuItem
                  className={styles.control}
                  icon={<PropertyTypeIcon type={type.id as PropertyType} />}
                >
                  {type.name}
                </MenuItem>
              ) : (
                <>Pick a data type...</>
              )}
            </Select>
          </Field>

          {isAnyRelation(def) && (
            <Field label="Relates to">
              <EntityTypeSelect
                searchable={false}
                additional={["person", "team"]}
                value={justOne(def.options?.references) || "task"}
                scope={db.scope}
                className={{ trigger: styles.control }}
                portal={true}
                onChange={(t) =>
                  t &&
                  onChange((d) => ({
                    options: { ...d.options, references: t },
                  }))
                }
              />
            </Field>
          )}

          {!!formatOptions.length && (
            <Field label="Show as">
              <Select
                searchable={false}
                clearable={true}
                options={formatOptions}
                value={find(formatOptions, { id: def.format })}
                portal={true}
                onChange={(v) =>
                  v && onChange({ format: v.id as Maybe<PropertyFormat> })
                }
              >
                <MenuItem className={styles.control}>
                  {!!def.format &&
                    find(formatOptions, { id: def.format })?.name}
                  {!def.format && <Text subtle>No formatting</Text>}
                </MenuItem>
              </Select>
            </Field>
          )}
        </SpaceBetween>
      )}

      {!!def.label && (
        <>
          <Field label={"Options"}>
            <CheckMenuItem
              text="Always show"
              checked={def.visibility === PropertyVisibility.ShowAlways}
              onChecked={(c) =>
                onChange({
                  visibility:
                    def.visibility === PropertyVisibility.ShowAlways
                      ? PropertyVisibility.HideEmpty
                      : PropertyVisibility.ShowAlways,
                })
              }
            />

            {canAiAssist(def) && (
              <CheckMenuItem
                text="Automatically suggest values (Ai Assist)"
                checked={def.assist !== AiAssist.Off}
                onChecked={(c) =>
                  onChange({
                    assist: !c ? AiAssist.Off : AiAssist.Auto,
                  })
                }
              />
            )}
          </Field>

          {(isAnySelect(def) || def.type === "status") && (
            <Field
              label={"Values"}
              help="List of allowed values to choose from."
            >
              {!!groupedValues &&
                map(keys(groupedValues) as StatusGroup[], (group) => (
                  <>
                    <DropTarget group={group}>
                      <Divider
                        className={styles.divider}
                        label={toStatusLabel({ group })}
                      />
                      {!groupedValues?.[group]?.length && (
                        <EmptyMenuItem>None</EmptyMenuItem>
                      )}
                    </DropTarget>

                    {map(groupedValues?.[group] || [], (ref) => (
                      <SelectValueItem
                        key={toKey(ref)}
                        value={ref}
                        def={def}
                        order={propValues?.indexOf(ref)}
                        update={(changes) => updateValue(ref, changes)}
                        onReorder={onReorder}
                      />
                    ))}
                  </>
                ))}

              {!groupedValues &&
                map(propValues || [], (ref, i) => (
                  <SelectValueItem
                    key={toKey(ref)}
                    value={ref}
                    def={def}
                    order={i}
                    update={(changes) => updateValue(ref, changes)}
                    onReorder={onReorder}
                  />
                ))}

              <AddInlineMenuItem
                placeholder="Add value"
                onAdd={handleAddValue}
              />

              <CheckMenuItem
                text="Lock values (prevent other values being used)"
                checked={!!def.locked}
                onChecked={(c) => onChange({ locked: c })}
              />
            </Field>
          )}

          <CollapsibleSection title="Advanced" defaultOpen={false}>
            <Field label="Show on">
              <EntityTypeMultiSelect
                searchable={false}
                value={def.entity}
                portal={true}
                additional={["person", "team"]}
                scope={db.scope}
                onChange={(ts) =>
                  onChange({
                    entity: ts,
                  })
                }
              />
            </Field>
          </CollapsibleSection>

          <CollapsibleSection title="Destructive" defaultOpen={false}>
            <Container padding="vertical" stack="horizontal">
              <DeleteButton
                onConfirm={() => onDelete(def as PropertyDef<Entity>)}
                variant="danger"
                fit="content"
              >
                Delete
              </DeleteButton>
            </Container>
          </CollapsibleSection>
        </>
      )}
    </VStack>
  );
};

const SelectValueItem = ({
  value,
  def,
  order,
  update,
  onReorder,
}: {
  def: PropertyDef<Entity>;
  value: PropertyValueRef<Entity>;
  update: Fn<Maybe<Partial<SelectOption | Status>>, void>;
  order: number;
  onReorder: (
    ref: PropertyValueRef<Entity, PropertyType>,
    from: number,
    to: DragToRef
  ) => void;
}) => {
  const rowRef = useRef<HTMLDivElement>(null);

  const selectOption = useMemo(
    () =>
      ensureMany(
        value.value[def.type as "select" | "multi_select" | "status"]
      )[0],
    [value]
  );

  if (!selectOption) {
    throw new Error("Missing select value.");
  }

  const updateColor = useCallback(
    (color: Color) => update({ color: color }),
    [update]
  );

  const updateName = useCallback((name: string) => update({ name }), [update]);

  const { dropping } = usePropertyValueDragDrop({
    property: value,
    order: order,
    ref: rowRef,
    group: value.value.status?.group,
    onReorder: (from, to) => onReorder(from.ref, from.order, to),
  });

  return (
    <div ref={rowRef}>
      {dropping && <DropHighlight />}

      <MenuItem iconRight={DragHandle}>
        <HStack align="center">
          {def.type === "status" && <StatusIcon status={value.value.status} />}

          <ColorSelect
            color={sansDefault(selectOption?.color)}
            canClear
            onChange={(t) => t && updateColor(t)}
          >
            <Icon
              size="xsmall"
              icon={<Swatch color={sansDefault(selectOption?.color)} />}
            />
          </ColorSelect>

          {isAnySelect(def) ? (
            <Tag color={selectOption?.color}>
              <EditableText
                key={toKey(value)}
                text={toLabel(value) || ""}
                updateOn="blur"
                placeholder="Empty..."
                onChange={(t) => updateName(t)}
                className={cx(styles.inherit, styles.editableText)}
              />
            </Tag>
          ) : (
            <EditableText
              key={toKey(value)}
              text={toLabel(value) || " "}
              placeholder="Empty..."
              updateOn="blur"
              onChange={(t) => updateName(t)}
              className={styles.editableText}
            />
          )}
          <FillSpace> </FillSpace>
          <Icon icon={TrashAlt} onClick={(e) => update(undefined)} />
        </HStack>
      </MenuItem>
    </div>
  );
};

export const PropertyCreateDialog = ({
  source,
  ...props
}: Omit<EditDialogProps, "prop">) => {
  const existing = useLazyProperties(source, false);
  const prop = useMemo(
    () =>
      newPropertyDef({
        scope: toBaseScope(source.scope),
        order: existing.length,
        entity: [source.type],
      }),
    []
  );

  return <PropertyEditDialog source={source} prop={prop} {...props} />;
};

export const PropertyRefEditDialog = ({
  source,
  prop,
  ...props
}: Omit<EditDialogProps, "prop"> & { prop: PropertyRef<Entity> }) => {
  const def = useLazyPropertyDef(source, prop);

  if (!def) {
    return <></>;
  }

  return <PropertyEditDialog source={source} prop={def} {...props} />;
};
