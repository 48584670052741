import { CSSProperties, ReactNode, Ref, forwardRef, useMemo } from "react";

import { cx } from "@utils/class-names";
import { ComponentOrNode } from "@utils/react";
import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";

import { Label } from "@ui/label";
import { FillSpace, SpaceBetween } from "@ui/flex";
import { CheckedState, CheckIcon, Icon } from "@ui/icon";

import styles from "./styles.module.css";
export interface MenuItemProps {
  // Use either text or children
  text?: string;
  children?: ReactNode;
  icon?: ComponentOrNode;
  iconRight?: ComponentOrNode;
  indent?: number;
  inset?: boolean;
  wrapLabel?: boolean;
  disabled?: boolean;
  dark?: boolean;
  selected?: boolean;
  checked?: Maybe<CheckedState>;
  className?: string;
  style?: CSSProperties;
  tabIndex?: number;
  onClick?: Fn<React.MouseEvent, void>;
  onDoubleClick?: Fn<React.MouseEvent, void>;
}

export const MenuItem = forwardRef(
  (
    {
      children,
      icon,
      iconRight,
      text,
      indent,
      inset,
      style,
      disabled = false,
      wrapLabel = true,
      dark = false,
      selected = false,
      checked,
      className,
      tabIndex,
      onDoubleClick,
      onClick,
    }: MenuItemProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const cns = useMemo(
      () =>
        cx(
          styles.menuItem,
          !icon && styles.noIcon,
          dark && styles.dark,
          disabled && styles.disabled,
          selected && styles.selected,
          className
        ),
      [icon, dark, disabled, selected, className]
    );
    const sty = useMemo(
      () => ({
        marginLeft: `${(indent || 0) * 10 - (inset ? 6 : 0)}px`,
        marginRight: `${inset ? 6 : 0}px`,
        width: `calc(100% - ${(indent || 0) * 10 - (inset ? 6 * 2 : 0)}px)`,
        ...style,
      }),
      [indent, inset, style]
    );
    return (
      <div
        ref={ref}
        className={cns}
        style={sty}
        tabIndex={tabIndex}
        onDoubleClick={onDoubleClick && !disabled ? onDoubleClick : undefined}
        onClick={onClick && !disabled ? onClick : undefined}
      >
        <SpaceBetween gap={4} align="center">
          {when(checked, (c) => (
            <Icon icon={<CheckIcon checked={c} />} />
          ))}
          {wrapLabel ? (
            <Label icon={icon} iconRight={iconRight} className={styles.label}>
              {text || children}
            </Label>
          ) : (
            <>
              {when(icon, (i) => (
                <Icon icon={i} />
              ))}
              <FillSpace>{children}</FillSpace>
              {when(iconRight, (i) => (
                <Icon icon={i} />
              ))}
            </>
          )}
        </SpaceBetween>
      </div>
    );
  }
);
