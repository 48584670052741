import { useMemo } from "react";
import { findLast, replace } from "lodash";

import { EntityType } from "@api";

import { useLazyEntity } from "@state/generic";

import { fromScope } from "@utils/scope";
import { maybeTypeFromId } from "@utils/id";
import { switchEnum } from "@utils/logic";
import { when } from "@utils/maybe";

import ProjectPage from "@ui/page/project-page";
import BacklogPage from "@ui/page/backlog-page";
import TeamPage from "@ui/page/team-page";
import { ViewPage } from "@ui/page/view-page";
import TaskPage from "@ui/page/task-page";
import OutcomePage from "@ui/page/outcome-page";
import { SettingsPage } from "@ui/page/settings-page";
import HomePage from "@ui/page/home-page";
import PersonPage from "@ui/page/person-page";
import InboxPage from "@ui/page/inbox-page";
import ResourcePage from "@ui/page/resource-page";
import SprintPage from "@ui/page/sprint-page";
import EntityPage from "@ui/page/entity-page";
import CalendarPage from "@ui/page/calendar-page";
import RoadmapPage from "@ui/page/roadmap-page";
import { getEngine, render } from "@ui/engine";
import { Redirect } from "@ui/redirect";
import { ImportWorkPage } from "./import-work-page";
import MeetingPage from "./meeting-page";
import { usePathName } from "@utils/navigation";

interface Props {
  part: string;
  parents?: string[];
}

export function AppRoute({ part, parents }: Props) {
  return useMemo(() => {
    const first = parents?.[0];
    const isTeamNesting = !!first && maybeTypeFromId(first) === "team";
    // Update on new entity
    // Otherwise route base on last item
    return switchEnum(maybeTypeFromId(part) || part, {
      action: () => <ParentRedirect entityId={part} type="meeting" />,
      agenda: () => <ParentRedirect entityId={part} type="meeting" />,

      // Non-redirects
      project: () => <ProjectPage projectId={part} />,
      backlog: () => <BacklogPage id={part} />,
      sprint: () => <SprintPage sprintId={part} />,
      meeting: () => <MeetingPage meetingId={part} />,
      team: () => <TeamPage teamId={part} />,
      view: () => <ViewPage viewId={part} />,
      task: () => <TaskPage taskId={part} />,
      content: () => <EntityPage id={part} />,
      campaign: () => <EntityPage id={part} />,
      page: () => <EntityPage id={part} />,
      process: () => <EntityPage id={part} />,
      calendar: () => <CalendarPage id={part} />,
      roadmap: () => <RoadmapPage id={part} />,
      resource: () => <ResourcePage resourceId={part} />,
      outcome: () => <OutcomePage outcomeId={part} />,
      settings: () => <SettingsPage />,
      home: () => <HomePage />,
      person: () => <PersonPage personId={part} />,
      inbox: () => <InboxPage />,
      import: () => <ImportWorkPage />,
      else: () => {
        const engine = when(maybeTypeFromId<EntityType>(part), getEngine);

        if (engine && engine.asPage) {
          return render(engine.asPage, { id: part });
        }

        return isTeamNesting ? (
          <TeamPage teamId={first} />
        ) : (
          <EntityPage id={part} />
        );
      },
    });
  }, [part, parents?.length]);
}

const ParentRedirect = ({
  entityId,
  type,
}: {
  entityId: string;
  type: EntityType;
}) => {
  const path = usePathName();
  const entity = useLazyEntity(entityId);
  const parentPath = useMemo(() => {
    const parentId = findLast(
      fromScope(entity?.source.scope),
      (t) => maybeTypeFromId(t) === type
    );
    return replace(path, entityId, parentId || "");
  }, [entity?.source.scope, type]);

  if (!entity || !parentPath) {
    return <></>;
  }

  return <Redirect to={parentPath} />;
};
