import { filter, map, tail } from "lodash";
import { join } from "path";
import { useCallback, useEffect, useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { ID } from "@graph";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyEntities, useUpdateEntity } from "@state/generic";
import { SystemPackages, useHasPackages } from "@state/packages";
import { useAddToRecents } from "@state/recents";
import { useEntityLabels } from "@state/settings";
import {
  toLabel as toTeamLabel,
  useLazyGetTeam,
  useLazyPeopleForTeam,
  useTeamPushTo,
} from "@state/teams";
import {
  toTemplateViewId,
  useLazyItemsForView,
  useLazyViewsForParent,
} from "@state/views";

import { respectHandled } from "@utils/event";
import { useQueryParams, useShowMore, useStickyState } from "@utils/hooks";
import { Maybe, when } from "@utils/maybe";
import { trimSlashes, useSyncPathnameID } from "@utils/url";
import { plural } from "@utils/string";
import { useGoTo, usePushTo } from "@utils/navigation";
import { toNotionUrl } from "@utils/notion";
import { toPropertyValueRef } from "@utils/property-refs";
import { toSlackUrl } from "@utils/slack";
import { asAppendMutation } from "@utils/property-mutations";
import { Fn } from "@utils/fn";

import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { CardHeader } from "@ui/card-header";
import { Centered, Container } from "@ui/container";
import { render, toEngine } from "@ui/engine";
import { HStack, HalfSpace, SpaceBetween, VStack } from "@ui/flex";
import {
  ArrowRight,
  ArrowUpRight,
  Box,
  Cog,
  Home,
  Icon,
  Notion,
  PaintTool,
  PersonIconStack,
  PinSlash,
  PlusIcon,
  Process,
  Redo,
  Schedule,
  SlackColor,
  TagAlt,
  TeamIcon,
  UserIcon,
} from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem, MoreMenuItem, RouteMenuItem } from "@ui/menu-item";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { SettingsTeamPackages } from "@ui/settings-packages";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { TeamPeople as TeamPeopleSection } from "@ui/team-people";
import { Heading, Text, TextMedium } from "@ui/text";
import WithParams from "@ui/with-params";
import AppPage from "./app-page";
import { ColorSelect } from "@ui/select/color";
import SettingsTeam, {
  SettingsTeamProperties,
  SettingsTeamSchedules,
  SettingsTeamTemplates,
} from "@ui/settings-team";
import { RelationIcon, RelationLabel } from "@ui/relation-label";
import { EntityViewResultsPage } from "@ui/view-results-page";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { GlobalEntitySelect } from "@ui/select";

import styles from "./team-page.module.css";

interface Props {
  teamId: string;
  viewId?: string;
}

const TeamPage = ({ teamId: _teamId }: Props) => {
  const goTo = useGoTo();
  const pushTo = usePushTo();
  const location = useLocation();
  const params = useQueryParams();
  const team = useLazyGetTeam(_teamId);
  // Team property/packages do not support saving temp ids, so make sure we're always using the real persisted id
  const teamId = useMemo(() => team?.id || _teamId, [_teamId, team?.id]);
  const mutate = useUpdateEntity(teamId);
  const toLabel = useEntityLabels(teamId);
  const pinned = useLazyEntities(team?.refs?.pins || []);
  const outcomeViews = useLazyViewsForParent(teamId, "outcome", false);
  const taskViews = useLazyViewsForParent(teamId, "task", false);
  const contentViews = useLazyViewsForParent(teamId, "content", false);
  const installed = useHasPackages(teamId, [
    SystemPackages.Campaigns,
    SystemPackages.Meetings,
    SystemPackages.Calendars,
    SystemPackages.Projects,
    SystemPackages.Content,
    SystemPackages.Backlogs,
    SystemPackages.Tasks,
    SystemPackages.Roadmaps,
    SystemPackages.Outcomes,
    SystemPackages.Sprints,
    SystemPackages.Pages,
    SystemPackages.Processes,
  ]);
  const [lastTeamPage, setLastTeamPage] = useStickyState<Maybe<string>>(
    undefined,
    `team-${teamId}-last-page`
  );
  const childRoute = useMemo(
    () => ({
      pathname:
        "/" + tail(trimSlashes(location.pathname).split("/"))?.join("/"),
    }),
    [location]
  );
  const [page] = useRegisterPage(teamId, team);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(_teamId, team?.id);

  // Add team to recently viewed
  useAddToRecents(teamId);

  const toRoute = useCallback(
    (route?: string) => "/" + (route ? join(teamId, route) : join(teamId)),
    [teamId]
  );
  const isOverview = useMemo(
    () => location.pathname === toRoute() || location.pathname === toRoute("/"),
    [location.pathname]
  );

  const size = useMemo(() => {
    const pn = location?.pathname;
    return pn?.includes("v_") || pn?.includes("/packages")
      ? "full"
      : "primary-thicc";
  }, [location.pathname]);

  useEffect(() => {
    if (params.goTo === "last" && isOverview && lastTeamPage) {
      goTo(lastTeamPage, {}, { replace: true });
    }
  }, []);

  useEffect(() => {
    setLastTeamPage(location.pathname);
  }, [setLastTeamPage, location.pathname, isOverview]);

  if (params.goTo === "last") {
    return <></>;
  }

  return (
    <AppPage page={page}>
      <StackContainer>
        <Sheet size={size} transparency="mid" interactable={false}>
          <PageLayout>
            <SideNav className={styles.nav}>
              <SpaceBetween direction="vertical">
                <Menu>
                  <VStack gap={6} className={styles.teamName}>
                    <SpaceBetween>
                      <ColorSelect
                        color="green"
                        onChange={(color) =>
                          mutate({
                            field: "color",
                            type: "text",
                            value: { text: color },
                          })
                        }
                      >
                        {team && (
                          <Icon icon={<TeamIcon team={team} />} size="xlarge" />
                        )}
                      </ColorSelect>
                      <HStack gap={0}>
                        {team?.notionId && (
                          <Button
                            icon={Notion}
                            subtle
                            onClick={respectHandled(() =>
                              when(team?.notionId, (n) => goTo(toNotionUrl(n)))
                            )}
                          />
                        )}
                        {when(
                          team &&
                            toPropertyValueRef(team, {
                              field: "settings.channel",
                              type: "text",
                            }),
                          ({ value }) => (
                            <Button
                              icon={SlackColor}
                              subtle
                              onClick={respectHandled(() =>
                                when(value.text, (n) => goTo(toSlackUrl(n)))
                              )}
                            />
                          )
                        )}
                      </HStack>
                    </SpaceBetween>
                    <Heading bold>{when(team, toTeamLabel)}</Heading>
                  </VStack>

                  <MenuGroup className={styles.menuGroup}>
                    <RouteMenuItem
                      text="Overview"
                      icon={Home}
                      iconRight={ArrowRight}
                      route={toRoute("/")}
                    />
                    <RouteMenuItem
                      icon={UserIcon}
                      text={plural(toLabel("person"))}
                      iconRight={ArrowRight}
                      route={toRoute("/people")}
                    />

                    {installed[SystemPackages.Meetings] && (
                      <RouteMenuItem
                        icon={Schedule}
                        text={plural(toLabel("meeting"))}
                        iconRight={ArrowRight}
                        route={toRoute("/meetings")}
                      />
                    )}

                    {installed[SystemPackages.Processes] && (
                      <RouteMenuItem
                        icon={Process}
                        text={plural(toLabel("process"))}
                        iconRight={ArrowRight}
                        route={toRoute("/processes")}
                      />
                    )}

                    {/* {installed[SystemPackages.Pages] && (
                      <RouteMenuItem
                        text={plural(toLabel("page"))}
                        iconRight={ArrowRight}
                        route={toRoute("/pages")}
                      />
                    )} */}
                  </MenuGroup>

                  {!!pinned?.length && (
                    <MenuGroup label="Pinned" className={styles.menuGroup}>
                      {map(pinned, (p) => (
                        <ContextMenu
                          key={p.id}
                          actions={
                            <>
                              <ContextItem
                                icon={PinSlash}
                                onClick={() =>
                                  mutate(
                                    asAppendMutation(
                                      { field: "refs.pins", type: "relations" },
                                      [p],
                                      "remove"
                                    )
                                  )
                                }
                              >
                                Unpin from team
                              </ContextItem>
                            </>
                          }
                        >
                          <MenuItem
                            iconRight={ArrowUpRight}
                            onClick={() => pushTo(p)}
                          >
                            <RelationLabel relation={p} />
                          </MenuItem>
                        </ContextMenu>
                      ))}
                      <GlobalEntitySelect
                        portal={true}
                        scope={team?.id}
                        value={undefined}
                        onChange={(p) =>
                          p &&
                          mutate(
                            asAppendMutation(
                              { field: "refs.pins", type: "relations" },
                              [p]
                            )
                          )
                        }
                      >
                        <MenuItem icon={PlusIcon}>
                          <Text subtle>Add pin</Text>
                        </MenuItem>
                      </GlobalEntitySelect>
                    </MenuGroup>
                  )}

                  <MenuGroup label="Organisation" className={styles.menuGroup}>
                    {installed[SystemPackages.Roadmaps] && (
                      <RouteMenuItem
                        icon={Box}
                        text={plural(toLabel("roadmap"))}
                        iconRight={ArrowRight}
                        route={toRoute("/roadmaps")}
                      />
                    )}
                    {installed[SystemPackages.Calendars] && (
                      <RouteMenuItem
                        icon={Box}
                        text={plural(toLabel("calendar"))}
                        iconRight={ArrowRight}
                        route={toRoute("/calendars")}
                      />
                    )}
                    {installed[SystemPackages.Campaigns] && (
                      <RouteMenuItem
                        icon={Box}
                        text={plural(toLabel("campaign"))}
                        iconRight={ArrowRight}
                        route={toRoute("/campaigns")}
                      />
                    )}
                    {installed[SystemPackages.Backlogs] && (
                      <RouteMenuItem
                        icon={Box}
                        text={plural(toLabel("backlog"))}
                        iconRight={ArrowRight}
                        route={toRoute("/backlogs")}
                      />
                    )}
                    {installed[SystemPackages.Projects] && (
                      <RouteMenuItem
                        icon={Box}
                        text={plural(toLabel("project"))}
                        iconRight={ArrowRight}
                        route={toRoute("/projects")}
                      />
                    )}
                    {installed[SystemPackages.Sprints] && (
                      <RouteMenuItem
                        icon={Box}
                        text={plural(toLabel("sprint"))}
                        iconRight={ArrowRight}
                        route={toRoute("/sprints")}
                      />
                    )}
                  </MenuGroup>

                  <MenuGroup label="Work items" className={styles.menuGroup}>
                    {installed[SystemPackages.Outcomes] && (
                      <RouteMenuItem
                        icon={Box}
                        text={plural(toLabel("outcome"))}
                        iconRight={ArrowUpRight}
                        route={toRoute(outcomeViews[0]?.id || "/outcomes")}
                      />
                    )}

                    {installed[SystemPackages.Content] && (
                      <RouteMenuItem
                        icon={Box}
                        text={plural(toLabel("content"))}
                        iconRight={ArrowUpRight}
                        route={toRoute(contentViews?.[0]?.id || "/contents")}
                      />
                    )}

                    {installed[SystemPackages.Tasks] && (
                      <RouteMenuItem
                        icon={Box}
                        text={plural(toLabel("task"))}
                        iconRight={ArrowUpRight}
                        route={toRoute(taskViews?.[0]?.id || "/tasks")}
                      />
                    )}
                  </MenuGroup>

                  <MenuGroup label="Automations">
                    <RouteMenuItem
                      icon={PaintTool}
                      text="Templates"
                      iconRight={ArrowRight}
                      route={toRoute("/settings/templates")}
                    />
                    <RouteMenuItem
                      icon={Redo}
                      text="Recurring"
                      iconRight={ArrowRight}
                      route={toRoute("/settings/schedules")}
                    />
                  </MenuGroup>
                </Menu>

                <Menu>
                  <MenuGroup label="Settings">
                    <RouteMenuItem
                      text="Fields"
                      icon={TagAlt}
                      iconRight={ArrowRight}
                      route={toRoute("/settings/fields")}
                    />
                    <RouteMenuItem
                      text="Packages"
                      icon={Box}
                      iconRight={ArrowRight}
                      route={toRoute("/settings/packages")}
                    />
                    <RouteMenuItem
                      icon={Cog}
                      text="Team info"
                      iconRight={ArrowRight}
                      route={toRoute("/settings/data")}
                    />
                  </MenuGroup>
                </Menu>
              </SpaceBetween>
            </SideNav>
            <Main className={styles.main}>
              <Routes location={childRoute}>
                <Route path="/" element={<TeamOverview teamId={teamId} />} />

                <Route
                  path="/people"
                  element={team && <TeamPeople teamId={teamId} />}
                />

                <Route
                  path="/projects"
                  element={<TeamProjects teamId={teamId} />}
                />

                <Route
                  path="/backlogs"
                  element={<TeamBacklogs teamId={teamId} />}
                />

                <Route
                  path="/roadmaps"
                  element={<TeamRoadmaps teamId={teamId} />}
                />

                <Route
                  path="/calendars"
                  element={<TeamCalendars teamId={teamId} />}
                />

                <Route
                  path="/campaigns/*"
                  element={<TeamCampaigns teamId={teamId} />}
                />

                <Route
                  path="/sprints"
                  element={<TeamSprints teamId={teamId} />}
                />

                <Route
                  path="/tasks/*"
                  element={<TeamTasks teamId={teamId} />}
                />

                <Route
                  path="/meetings/*"
                  element={<TeamMeetings teamId={teamId} />}
                />

                <Route
                  path="/pages/*"
                  element={<TeamPages teamId={teamId} />}
                />

                <Route
                  path="/outcomes/*"
                  element={<TeamOutcomes teamId={teamId} />}
                />

                <Route
                  path="/contents/*"
                  element={<TeamContent teamId={teamId} />}
                />

                <Route
                  path="/processes"
                  element={<TeamProcesses teamId={teamId} />}
                />

                <Route
                  path="/settings/templates"
                  element={<SettingsTeamTemplates teamId={teamId} />}
                />

                <Route
                  path="/settings/schedules"
                  element={<SettingsTeamSchedules teamId={teamId} />}
                />

                <Route
                  path="/settings/fields"
                  element={<SettingsTeamProperties teamId={teamId} />}
                />

                <Route
                  path="/settings/packages"
                  element={<SettingsTeamPackages teamId={teamId} />}
                />

                <Route
                  path="/settings/data"
                  element={<SettingsTeam teamId={teamId} />}
                />
                <Route
                  path="/:viewId"
                  element={
                    <WithParams
                      params={({ viewId }) => ({ teamId, viewId })}
                      component={TeamTasks}
                    />
                  }
                />
              </Routes>
            </Main>
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};

const TeamOverview = ({ teamId }: Props) => {
  const pushTo = useTeamPushTo(teamId);
  const goTo = useGoTo();
  const team = useLazyGetTeam(teamId);
  const people = useLazyPeopleForTeam(teamId);
  const members = useMemo(
    () => filter(people, (p) => p.id !== team?.owner?.id),
    [people, team?.owner?.id]
  );
  const toLabel = useEntityLabels(teamId);
  const installed = useHasPackages(team?.id || "", [
    SystemPackages.Projects,
    SystemPackages.Meetings,
    SystemPackages.Campaigns,
    SystemPackages.Calendars,
    SystemPackages.Backlogs,
    SystemPackages.Content,
    SystemPackages.Roadmaps,
    SystemPackages.Outcomes,
    SystemPackages.Sprints,
    SystemPackages.Pages,
  ]);

  if (!team) {
    return <></>;
  }

  return (
    <Container>
      <Container
        fit="container"
        stack="horizontal"
        gap={20}
        wrap
        align="stretch"
      >
        {/* <TeamHeader team={team} /> */}

        <Card onClick={respectHandled(() => pushTo("/people"))}>
          <SpaceBetween>
            <HStack>
              {team.owner && (
                <Icon
                  icon={<RelationIcon relation={team.owner} />}
                  size="xlarge"
                />
              )}
              <PersonIconStack people={members} onSelected={goTo} />
            </HStack>
            <Button subtle iconRight={ArrowUpRight}>
              <Text subtle>Manage</Text>
            </Button>
          </SpaceBetween>
        </Card>

        {installed[SystemPackages.Meetings] && (
          <TeamOverviewCard
            viewId={toTemplateViewId("today-meetings", {
              parent: teamId,
            })}
            label={plural(toLabel("meeting"))}
            onClick={() => pushTo("/meetings")}
            height="content"
          />
        )}

        {installed[SystemPackages.Roadmaps] && (
          <HalfSpace gap={20}>
            <TeamOverviewCard
              viewId={toTemplateViewId("team-roadmap", { parent: teamId })}
              label={plural(toLabel("roadmap"))}
              onClick={() => pushTo("/roadmaps")}
            />
          </HalfSpace>
        )}

        {installed[SystemPackages.Calendars] && (
          <HalfSpace gap={20}>
            <TeamOverviewCard
              viewId={toTemplateViewId("team-calendar", { parent: teamId })}
              label={plural(toLabel("calendar"))}
              onClick={() => pushTo("/calendars")}
            />
          </HalfSpace>
        )}

        {installed[SystemPackages.Campaigns] && (
          <HalfSpace gap={20}>
            <TeamOverviewCard
              viewId={toTemplateViewId("team-campaign", { parent: teamId })}
              label={plural(toLabel("campaign"))}
              onClick={() => pushTo("/campaigns")}
            />
          </HalfSpace>
        )}

        {installed[SystemPackages.Backlogs] && (
          <HalfSpace gap={20}>
            <TeamOverviewCard
              viewId={toTemplateViewId("team-backlog", { parent: teamId })}
              label={plural(toLabel("backlog"))}
              onClick={() => pushTo("/backlogs")}
            />
          </HalfSpace>
        )}

        {installed[SystemPackages.Sprints] && (
          <HalfSpace gap={20}>
            <TeamOverviewCard
              viewId={toTemplateViewId("team-sprint", { parent: teamId })}
              label={plural(toLabel("sprint"))}
              onClick={() => pushTo("/sprints")}
            />
          </HalfSpace>
        )}
        {/* 
        {installed[SystemPackages.Pages] && (
          <HalfSpace gap={20}>
            <TeamOverviewCard
              viewId={toTemplateViewId("team-page", { parent: teamId })}
              label={plural(toLabel("page"))}
              onClick={() => pushTo("/pages")}
            />
          </HalfSpace>
        )} */}

        {installed[SystemPackages.Projects] && (
          <HalfSpace gap={20}>
            <TeamOverviewCard
              viewId={toTemplateViewId("team-project", { parent: teamId })}
              label={plural(toLabel("project"))}
              onClick={() => pushTo("/projects")}
            />
          </HalfSpace>
        )}

        {installed[SystemPackages.Outcomes] && (
          <HalfSpace gap={20}>
            <TeamOverviewCard
              viewId={toTemplateViewId("team-outcome-current", {
                parent: teamId,
              })}
              label={plural(toLabel("outcome"))}
              onClick={() => pushTo("/outcomes")}
            />
          </HalfSpace>
        )}
      </Container>
    </Container>
  );
};

const TeamProjects = ({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="project" />;
};

const TeamBacklogs = ({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="backlog" />;
};

const TeamRoadmaps = ({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="roadmap" />;
};

const TeamCalendars = ({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="calendar" />;
};

const TeamSprints = ({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="sprint" />;
};

const TeamProcesses = ({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="process" />;
};

const TeamPeople = ({ teamId }: Props) => {
  const team = useLazyGetTeam(teamId);
  const mutate = useUpdateEntity(teamId);

  if (!team) {
    return <></>;
  }

  return (
    <Container>
      <Centered fit="container" stack="vertical" gap={20}>
        <TeamPeopleSection team={team} mutate={mutate} />
      </Centered>
    </Container>
  );
};

const TeamOverviewCard = ({
  viewId,
  label,
  height = "container",
  onClick,
}: {
  label?: string;
  viewId: ID;
  height?: "content" | "container";
  onClick: Fn<void, void>;
}) => {
  const items = useLazyItemsForView(viewId);
  const pushTo = usePushTo();
  const { visible, hasMore, moreCount } = useShowMore(
    items.items.sorted || items?.items.all,
    5
  );
  return (
    <Card onClick={respectHandled(() => onClick?.())} height={height}>
      <CardHeader padding="none" border={false}>
        <TextMedium bold>{label}</TextMedium>
      </CardHeader>

      <Menu>
        <MenuGroup>
          {map(visible, (o) =>
            render(toEngine(o)?.asMenuItem, {
              key: o.id,
              item: o,
              onOpen: pushTo,
            })
          )}
          {hasMore && <MoreMenuItem text="Show all" count={moreCount} />}
        </MenuGroup>
      </Menu>
    </Card>
  );
};

const TeamMeetings = ({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="meeting" />;
};

const TeamPages = ({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="page" />;
};

const TeamTasks = ({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="task" />;
};

const TeamCampaigns = ({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="campaign" />;
};

const TeamOutcomes = ({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="outcome" />;
};

const TeamContent = ({ teamId }: Props) => {
  return <EntityViewResultsPage parentId={teamId} childType="content" />;
};
export default TeamPage;
