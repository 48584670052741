import { useDebouncedCallback } from "use-debounce";
import { addMinutes, differenceInMinutes, differenceInSeconds } from "date-fns";
import { useCallback, useEffect, useMemo } from "react";
import useSound from "use-sound";

import { Meeting } from "@api";

import {
  isInProgress,
  toDurationMins,
  useAutoStartMeeting,
} from "@state/meetings";
import { useSettingState } from "@state/settings";

import { Maybe } from "@utils/maybe";
import { plural } from "@utils/string";
import { isNowish, latest } from "@utils/date";
import {
  fromPointDate,
  PointDate,
  toPointDate,
  useISODate,
} from "@utils/date-fp";
import { now, useTick } from "@utils/time";

import { useCurrentPage } from "@ui/app-page";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { PaneContainer, PaneHeader } from "@ui/pane-header";
import ProgressBar from "@ui/progress-bar";
import { ColoredSection } from "@ui/section";
import { Sheet } from "@ui/sheet-layout";
import { Text, TextLarge } from "@ui/text";
import { TimeLabel } from "@ui/time-label";
import { WorkflowActions } from "@ui/workflow-action-button";
import { Divider } from "@ui/divider";
import {
  Icon,
  Play,
  StopCircle,
  Stopwatch,
  Volume,
  VolumeMute,
} from "@ui/icon";
import { Button } from "@ui/button";
import { DurationPicker } from "@ui/time-picker";
import { showSuccess } from "@ui/notifications";
import { RelationLabel } from "@ui/relation-label";

import styles from "./host-pane.module.css";

interface Props {
  meeting: Meeting;
}

export const MeetingHostPane = ({ meeting }: Props) => {
  const running = useMemo(
    () => isInProgress(meeting),
    [meeting, useTick("10 seconds")]
  );

  // When start time has passed and host is looking at the meeting, start it
  useAutoStartMeeting(meeting);

  return (
    <Sheet size="secondary">
      <PaneHeader title="Host Tools" />

      <PaneContainer inset={false}>
        <VStack gap={12}>
          <Divider />
          <HStack fit="container" gap={4}>
            <WorkflowActions size="small" entity={meeting} />
          </HStack>

          {running && <TimeRemaining meeting={meeting} />}
          <Timer meeting={meeting} />
        </VStack>
      </PaneContainer>
    </Sheet>
  );
};

export const MeetingParticipantPane = ({ meeting }: Props) => {
  const running = isInProgress(meeting);

  return (
    <Sheet size="secondary">
      <PaneHeader title="Meeting tools" />

      <PaneContainer inset={false}>
        <VStack gap={12}>
          <Divider />
          {running && <TimeRemaining meeting={meeting} />}
          <TimerListener meeting={meeting} />
        </VStack>
      </PaneContainer>
    </Sheet>
  );
};

const TimeRemaining = ({ meeting }: Props) => {
  const meetingTime = useMemo(() => toDurationMins(meeting) || 0, [meeting]);
  const timeRemaining = useMemo(() => {
    if (!meeting.start || !meeting.end) {
      return 0;
    }

    const nowISO = toPointDate(now());
    const start = latest(meeting.start, nowISO) || nowISO;
    const remainig = useISODate(
      meeting.end,
      (end) => (end && differenceInMinutes(end, fromPointDate(start))) || 0
    );
    return remainig;
  }, [meeting, useTick("10 seconds")]);

  return (
    <ColoredSection color="background" className={styles.hostTool}>
      <VStack fit="container" gap={0}>
        {timeRemaining > 0 && <Text>{timeRemaining} mins remaining</Text>}
        {timeRemaining < 0 && <Text>{timeRemaining} mins overtime</Text>}

        <SpaceBetween fit="container" gap={10}>
          <TimeLabel
            subtle
            size="small"
            fit="content"
            bold={true}
            date={fromPointDate(meeting.start)}
          />

          <FillSpace>
            <ProgressBar
              color="red"
              percent={
                Math.min((meetingTime - timeRemaining) / meetingTime, 1) * 100
              }
            />
          </FillSpace>

          <TimeLabel
            subtle
            fit="content"
            size="small"
            bold={true}
            date={fromPointDate(meeting.end)}
          />
        </SpaceBetween>
      </VStack>
    </ColoredSection>
  );
};

const TimerListener = ({ meeting }: Props) => {
  const pageId = useCurrentPage();
  const [soundsOff] = useSettingState(
    meeting.id,
    "timer-sounds-off",
    "boolean",
    pageId
  );
  const [duration = 0] = useSettingState(
    meeting.id,
    "timer-duration",
    "number",
    pageId
  );
  const [start] = useSettingState(meeting.id, "timer-start", "date", pageId);
  const remaining = useRemaining(start, duration);
  const [play] = useSound("/SND_completed.m4a");

  useEffect(() => {
    if (start && useISODate(start, isNowish) && duration) {
      !soundsOff && play();
      showTimerStarted(duration);
    }
  }, [soundsOff, start]);

  useEffect(() => {
    if (remaining === 0) {
      showSuccess("Timer finished");
      play();
    }
  }, [remaining]);

  if (!start || !remaining) {
    return <></>;
  }

  return (
    <ColoredSection color="background" className={styles.hostTool}>
      <SpaceBetween fit="container" gap={10}>
        <TextLarge bold>{formatTimer(remaining)}</TextLarge>
        <HStack>
          <Text subtle>Started by</Text>
          <RelationLabel fit="content" relation={meeting.owner} />
        </HStack>
      </SpaceBetween>
    </ColoredSection>
  );
};

const Timer = ({ meeting }: Props) => {
  const pageId = useCurrentPage();
  const [soundsOff, setSoundsOff] = useSettingState(
    meeting.id,
    "timer-sounds-off",
    "boolean",
    pageId
  );
  // Local default of 1min will be set on the meeting when hitting play
  const [duration = 1, setDuration] = useSettingState(
    meeting.id,
    "timer-duration",
    "number",
    pageId
  );
  const [start, setStart] = useSettingState(
    meeting.id,
    "timer-start",
    "date",
    pageId
  );
  const remaining = useRemaining(start, duration);
  const [play] = useSound("/SND_completed.m4a");
  const playOnce = useDebouncedCallback(play, 10000, {
    leading: true,
    trailing: false,
  });

  const handleStart = useCallback(() => {
    // Ensure the duration is actually set on the meeting (not just as a local default)
    setDuration(duration);
    setStart(toPointDate(now()));
    !soundsOff && playOnce();
    showTimerStarted(duration);
  }, [soundsOff, duration]);

  useEffect(() => {
    if (remaining === 0) {
      showSuccess("Timer finished");
      playOnce();
      setStart(undefined);
    }
  }, [remaining]);

  return (
    <ColoredSection color="background" className={styles.hostTool}>
      <SpaceBetween fit="container" gap={10}>
        <DurationPicker mins={duration} onChange={setDuration}>
          <Button size="tiny" subtle icon={Stopwatch}>
            {(!start || !remaining) && (
              <TextLarge bold>{String(duration).padStart(2, "0")}:00</TextLarge>
            )}
            {!!start && !!remaining && (
              <TextLarge bold>{formatTimer(remaining)}</TextLarge>
            )}
          </Button>
        </DurationPicker>

        <HStack gap={0}>
          <Button
            size="tiny"
            subtle
            icon={!soundsOff ? Volume : VolumeMute}
            onClick={() => setSoundsOff(!soundsOff)}
          >
            Sound {!soundsOff ? "on" : "off"}
          </Button>

          {!remaining && (
            <Button size="small" subtle icon={Play} onClick={handleStart} />
          )}

          {!!remaining && (
            <Button
              size="small"
              subtle
              icon={StopCircle}
              onClick={() => setStart(undefined)}
            />
          )}
        </HStack>
      </SpaceBetween>
    </ColoredSection>
  );
};

const formatTimer = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds
  ).padStart(2, "0")}`;
};

const useRemaining = (start: Maybe<PointDate>, duration: Maybe<number>) => {
  return useMemo(() => {
    if (!start || !duration) {
      return undefined;
    }

    const seconds = useISODate(start, (s) =>
      differenceInSeconds(addMinutes(s, duration), now())
    );

    if (seconds < -10) {
      return undefined;
    }

    return Math.max(seconds, 0);
  }, [start, useTick("1 seconds")]);
};

const showTimerStarted = (duration: number) =>
  showSuccess(
    `Timer started for ${duration} ${plural("min", duration)}`,
    <Icon icon={Stopwatch} />
  );
