import { find, join, last, split, takeWhile } from "lodash";
import { ID } from "@api/types";
import { Maybe, when } from "./maybe";
import { isPersonId, isTeamId, isWorkspaceId } from "./id";
import { or } from "./logic";
import { composel } from "./fn";

// Scope is a way to define a hierarchy of objects. It is a string that is a series of IDs separated by slashes.
// For example, a scope could be `t_2okdok/p_2pslsp` which represents a project in a team

// Valid scopes
// w_* – Single top level workspace
// p_* – Single top level user
// tm_* – Single top level team
// tm_*/x_* – Anything (non-workspace-user) nested under team

// Location is a way to define where things live. Location is a type of scope.

// Create a scope key from ids passed in. Only take up to the first non-empty ID
export const toScope = (...args: Maybe<string>[]) =>
  join(takeWhile(args, Boolean), "/");

export const fromScope = (scope: Maybe<string>) => split(scope, "/") || [];

const isBaseScopePart = (part: Maybe<string>) =>
  when(part, composel(or(isTeamId, isWorkspaceId, isPersonId))) ?? false;

// Base scopes are the level to which you can define properties against. This is either a workspace, team or user.
export const toBaseScope = (scope: string): string =>
  toScope(...takeWhile(fromScope(scope), isBaseScopePart));

export function toParent(scope: string): string;
export function toParent(scope: Maybe<string>): Maybe<string>;
export function toParent(scope: Maybe<string>): Maybe<string> {
  return last(fromScope(scope)) || scope;
}

// Scope in the format of `w_sdsokod/t_2okdok` or `w_sdsokod/t_2okdok/p_2pslsp` or `w_sdsokod`
// method returns if the scopes overlap
export const overlaps = (s1: string, s2: string) =>
  s2?.startsWith(s1) || s1?.startsWith(s2);

export const replacePart = (scope: string, part: string, replaceWith: string) =>
  toScope(...fromScope(scope).map((p) => (p === part ? replaceWith : p)));

// If the scope is a workspace scope then it's always valid
// If the scope is a team scope and it overlaps with the scope you have it's valid
// If the scope is a user scope and it's the same as the scope you have it's valid
export const isMatch = (entityScope: Maybe<string>, compare: Maybe<string>) =>
  !!entityScope &&
  !!compare &&
  (isWorkspaceId(compare) || overlaps(compare, entityScope));

// takes a full scope like a/b/c/d and returns all permutations from the front
// such as a/b/c/d, a/b/c, a/b, a
export const toSubScopes = (scope: string) =>
  split(scope, "/")
    .map((_, i, a) => join(a.slice(0, i + 1), "/"))
    ?.reverse();

export const extractTeam = (scope: Maybe<string>) =>
  find(fromScope(scope), isTeamId);

export const extractLast = (scope: Maybe<string>) =>
  last(fromScope(scope)) || undefined;

// Converts a location to a source.scope
export const fromLocation = (location: string, workspaceId: string) => {
  // Source scope is now = location
  return location ?? workspaceId;
};

// Converts a source.scope to a location
export const toLocation = (scope: string) => {
  // Source scope is now = location
  return scope;
};

// Team and user locations don't nest
export const joinLocation = (location: string, part: ID) =>
  location?.includes(part) ? location : location + "/" + part;

// Team and users don't nest under other things
export const toChildLocation = (location: string, id: ID) =>
  isTeamId(id) || isPersonId(id) || isWorkspaceId(id)
    ? toScope(id)
    : toScope(...fromScope(location), id);

// Maintains the nested structure underneath the parent, but with a new top level location
export const toNestedLocation = (
  oldChildLocation: string,
  parentId: ID,
  newParentLocation: string
) => {
  if (!oldChildLocation?.includes(parentId)) {
    return joinLocation(newParentLocation, parentId);
  }

  return joinLocation(
    newParentLocation,
    oldChildLocation.slice(oldChildLocation.indexOf(parentId))
  );
};
