import { has } from "lodash";
import { Maybe } from "@utils/maybe";
import { Integration } from "./types";
import type {
  Task,
  Error,
  SourceID,
  ID,
  URL,
  DatabaseID,
  EntityType,
  Project,
  View,
  HasStatus,
  Outcome,
  HasResources,
  Entity,
  HasTitle,
  HasName,
  HasLocation,
  Team,
  Person,
  HasBody,
  HasNotes,
  HasCode,
  HasOrders,
  HasDates,
  HasFollowers,
  HasTemplate,
  HasRefs,
  HasColor,
  HasAssigned,
  HasOwner,
  Note,
  HasTimestamps,
  HasArchivedAt,
  Resource,
  HasOrder,
} from "./types";

export const sourceDB = (
  id: ID,
  type: EntityType,
  source: Integration = Integration.Notion
) => ({
  id,
  type,
  source,
});

export const notionDB = (id: ID, type: EntityType, team: ID): DatabaseID => ({
  source: Integration.Notion,
  type,
  scope: team,
});

export const notionID = (id: string): SourceID => ({
  source: Integration.Notion,
  id,
});

export const isError = <T>(thing: T | Error): thing is Error =>
  !!(thing as Error)?.code && !has(thing, "id");

export const isTemplate = (thing: Entity) =>
  !!thing && !!(thing as HasTemplate).template;

export const isTask = <T>(thing: T | Task): thing is Task =>
  (thing as Task)?.source?.type === "task";

export const isNote = <T>(thing: T | Note): thing is Note =>
  (thing as Note)?.source?.type === "note";

export const isTeam = <T>(thing: T | Team): thing is Team =>
  (thing as Team)?.source?.type === "team";

export const isPerson = <T>(thing: T | Person): thing is Person =>
  (thing as Person)?.source?.type === "person";

export const isResource = <T>(thing: T | Resource): thing is Resource =>
  (thing as Resource)?.source?.type === "resource";

export const isOutcome = <T>(thing: T | Outcome): thing is Outcome =>
  (thing as Outcome)?.source?.type === "outcome";

export const isProject = <T>(thing: T | Project): thing is Project =>
  (thing as Project)?.source?.type === "project";

export const isView = <T>(thing: T | View): thing is View =>
  (thing as View)?.source?.type === "view";

export const hasStatus = <T>(thing: T | HasStatus): thing is HasStatus =>
  !!thing && has(thing, "status");

// Update on new entity
export const isStatusable = (t: EntityType) =>
  [
    "task",
    "outcome",
    "project",
    "campaign",
    "content",
    "sprint",
    "schedule",
    "meeting",
    "action",
  ].includes(t);

export const hasLocation = <T extends object>(
  thing: Maybe<T> | HasLocation
): thing is HasLocation => !!thing && has(thing, "location");

export const hasDates = <T extends object>(
  thing: Maybe<T> | HasDates
): thing is HasDates => !!thing && has(thing, "start");

export const hasArchivedAt = <T extends object>(
  thing: Maybe<T> | HasArchivedAt
): thing is HasArchivedAt => !!thing && has(thing, "archivedAt");

export const hasOrders = <T extends object>(
  thing: Maybe<T> | HasOrders
): thing is HasOrders => !!thing && has(thing, "orders");

export const hasOrder = <T extends object>(
  thing: Maybe<T> | HasOrder
): thing is HasOrder => !!thing && has(thing, "order");

export const hasColor = <T extends object>(
  thing: Maybe<T> | HasColor
): thing is HasColor => !!thing && has(thing, "color");

export const hasCode = <T extends object>(
  thing: Maybe<T> | Extract<Entity, { code: Maybe<URL> }>
): thing is Extract<Entity, { code: Maybe<URL> }> =>
  !!thing && has(thing, "code");

export const hasIcon = <T extends object>(
  thing: Maybe<T> | Extract<Entity, { icon: Maybe<URL> }>
): thing is Extract<Entity, { icon: Maybe<URL> }> =>
  !!thing && has(thing, "icon");

export const hasCover = <T extends object>(
  thing: Maybe<T> | Extract<Entity, { cover: Maybe<URL> }>
): thing is Extract<Entity, { cover: Maybe<URL> }> =>
  !!thing && has(thing, "cover");

export const hasName = <T extends object>(
  thing: Maybe<T> | Extract<Entity, { name: Maybe<string> }>
): thing is Extract<Entity, { name: Maybe<string> }> =>
  !!thing && has(thing, "name");

export const hasResources = <T extends object>(
  thing: Maybe<T> | HasResources
): thing is HasResources => !!thing && has(thing, "refs");

export const hasFollowers = <T extends object>(
  thing: Maybe<T> | HasFollowers
): thing is HasFollowers =>
  !!thing &&
  has(thing, "refs") &&
  !["view", "agenda", "schedule", "workspace", "team", "schedule"]?.includes(
    (thing as Maybe<HasFollowers>)?.source?.type || "never"
  );

export const hasTimestamps = <T extends object>(
  thing: Maybe<T> | HasTimestamps
): thing is HasTimestamps => !!thing && has(thing, "stamps");

export const hasRefs = <T extends object>(
  thing: Maybe<T> | HasRefs
): thing is HasRefs => !!thing && has(thing, "refs");

export const hasNotes = <T extends object>(
  thing: Maybe<T> | HasNotes
): thing is HasNotes => !!thing && has(thing, "refs");

export const hasAssigned = <T extends object>(
  thing: Maybe<T> | HasAssigned
): thing is HasAssigned => !!thing && has(thing, "assigned");

export const hasOwner = <T extends object>(
  thing: Maybe<T> | HasOwner
): thing is HasOwner => !!thing && has(thing, "owner");

export const hasOwnerOrAssigned = <T extends object>(
  thing: Maybe<T> | HasOwner | HasAssigned
): thing is HasOwner | HasAssigned => hasOwner(thing) || hasAssigned(thing);

export const hasBody = <T extends object>(
  thing: Maybe<T> | HasBody
): thing is HasBody => !!thing && has(thing, "body");

export const toTitleOrName = (t: Entity) =>
  (t as HasTitle)?.title ||
  (t as HasName)?.name ||
  (t as Person)?.fullName ||
  (t?.source?.type === "page" && "Untitled") ||
  "";

export const toSearchable = (t: Entity) =>
  ((t as HasCode)?.code ? `${(t as HasCode)?.code}: ` : "") +
  (toTitleOrName(t) || (t as HasBody)?.body?.html);
