import { atomFamily, selectorFamily } from "recoil";

import { ID, Process } from "@api";

import {
  getItem,
  localStorageForStore,
  mergeItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type ProcessStoreState = StoreState<Process>;

export const WorkspaceProcessStoreAtom = atomFamily<ProcessStoreState, ID>({
  key: "WorkspaceProcessStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [localStorageForStore(wid, "process")],
});

export const ProcessStoreAtom = WorkspaceWrappedAtom(
  "ProcessStoreAtom",
  WorkspaceProcessStoreAtom
);

export const ProcessAtom = selectorFamily({
  key: "ProcessAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(ProcessStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(ProcessStoreAtom, mergeItem<Process>(newValue));
      }
    },
});
