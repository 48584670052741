import { flatMap } from "lodash";
import { useMemo, useState } from "react";
import { Note } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyEntity, useMarkAsSeen } from "@state/generic";
import { useTeamId } from "@state/teams";
import { useLazyProperties } from "@state/databases";
import { SystemPackages, useHasPackages } from "@state/packages";

import { usePushTo } from "@utils/navigation";
import { useSetQueryParams } from "@utils/hooks";
import { when } from "@utils/maybe";
import { isAnyRelation } from "@utils/property-refs";
import { ensureMany } from "@utils/array";
import { toChildLocation } from "@utils/scope";
import { isNoteId } from "@utils/id";

import { ProjectHeader } from "@ui/project-header";
import { useSyncPathnameID } from "@utils/url";
import { Centered } from "@ui/container";
import { OverlaySheet, Sheet, StackContainer } from "@ui/sheet-layout";
import { ResourcesPane } from "@ui/resources-pane";
import { EntityMessagesPane } from "@ui/messages-pane";
import { useAppPageContext } from "@ui/app-page";
import { UpdateThread } from "@ui/update-thread";
import { DiscussionThread } from "@ui/discussion-thread";
import { SummarySection } from "@ui/summary-section";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { PaneItem, PaneManager } from "@ui/pane-manager";
import { Comments, EmojiIcon, Process, Search } from "@ui/icon";
import { TemplateBanner, TemplateSideBar } from "@ui/template-banner";
import { SearchPane } from "@ui/search-pane";
import { RelatedMeetingsPane } from "@ui/engine/meeting";
import { RelatedProcessesPane } from "@ui/engine/process";
import { EntityProperties } from "@ui/entity-properties";

import AppPage from "./app-page";
import { ResourcesSection } from "@ui/resources-section";
import { GenericPageBody } from "@ui/page-body";
import { ViewRelationsSection } from "@ui/view-results-section";

interface Props {
  projectId: string;
}

const ProjectPage = ({ projectId }: Props) => {
  const pageId = useAppPageContext();
  const pushTo = usePushTo();
  const project = useLazyEntity<"project">(projectId);
  const teamId = useTeamId(project);
  const setParams = useSetQueryParams();
  const fullLocation = useMemo(
    () => when(project, (b) => toChildLocation(b.location, b.id)),
    [project?.location, project?.id]
  );
  const props = useLazyProperties(project?.source);
  const childTypes = useMemo(
    () =>
      flatMap(props, (p) =>
        isAnyRelation(p) ? ensureMany(p.options?.references) : []
      ),
    [props]
  );
  const installed = useHasPackages(projectId || "", [
    SystemPackages.Processes,
    SystemPackages.Meetings,
  ]);

  const [showNote, setShowNote] = useState<Note>();

  const [page] = useRegisterPage(projectId, project);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(projectId, project?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(projectId, pageId);

  if (!project) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!project} title={project?.name}>
      <StackContainer>
        <SmartBreadcrumbSheet />

        <Sheet size="primary">
          {!!project.template && <TemplateBanner />}

          <ProjectHeader project={project} />

          <Centered stack="vertical" gap={30}>
            <SummarySection entityId={projectId} />

            <GenericPageBody
              label="Project Brief"
              placeholder="What is this project all about..."
              entity={{ id: projectId, entity: "project" }}
              body={project.body}
              showAll={false}
            />

            <ResourcesSection entity={project} refs={project.refs?.resources} />

            {childTypes?.includes("outcome") && (
              <ViewRelationsSection
                parentId={project.id}
                childType="outcome"
                onOpen={pushTo}
              />
            )}

            {childTypes?.includes("content") && (
              <ViewRelationsSection
                parentId={project.id}
                childType="content"
                onOpen={pushTo}
              />
            )}

            {childTypes?.includes("task") && (
              <ViewRelationsSection
                parentId={project.id}
                childType="task"
                onOpen={pushTo}
              />
            )}
          </Centered>
        </Sheet>

        {!!project.template ? (
          <TemplateSideBar />
        ) : (
          <PaneManager size="secondary">
            <PaneItem id="search" title="Search" icon={Search}>
              <SearchPane
                parentId={project.id}
                onOpen={(n) =>
                  isNoteId(n.id) ? setShowNote(n as Note) : pushTo(n)
                }
              />
            </PaneItem>

            {fullLocation && (
              <PaneItem
                id="resources"
                title="Resources"
                icon={<EmojiIcon emoji="🔗" />}
              >
                <ResourcesPane location={fullLocation} />
              </PaneItem>
            )}

            <PaneItem
              id="messages"
              title="Messages"
              icon={<EmojiIcon emoji="💬" />}
            >
              <EntityMessagesPane
                entityId={projectId}
                onSelected={(n) => setShowNote(n)}
              />
            </PaneItem>

            {installed[SystemPackages.Meetings] && (
              <PaneItem id="meetings" title="Meetings" icon={Comments}>
                <RelatedMeetingsPane entityId={project.id} />
              </PaneItem>
            )}

            {installed[SystemPackages.Processes] && (
              <PaneItem id="processes" title="Processes" icon={Process}>
                <RelatedProcessesPane entityId={project.id} />
              </PaneItem>
            )}
          </PaneManager>
        )}

        {showNote && (
          <OverlaySheet
            size="secondary"
            height="container"
            onDismiss={() => setShowNote(undefined)}
          >
            {showNote?.type === "update" ? (
              <UpdateThread
                noteId={showNote?.id}
                onClose={() => setShowNote(undefined)}
              />
            ) : (
              <DiscussionThread
                noteId={showNote?.id}
                onClose={() => setShowNote(undefined)}
              />
            )}
          </OverlaySheet>
        )}
      </StackContainer>
    </AppPage>
  );
};

export default ProjectPage;
